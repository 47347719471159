import { lerp } from './canvas_math';

interface Vector<Vec> {
  multiply(x: Vec): Vec;
  add(x: Vec): Vec;

  lengthSqr(): number;
  normalize(): Vec;
}

/**
 * Readonly Class for representing a 2d vector and utility functions.
 */
export class Vector2 implements Vector<Vector2> {
  constructor(
    readonly x: number = 0,
    readonly y: number = 0,
  ) {}

  multiply(x: number): Vector2;
  multiply(x: number, y: number): Vector2;
  multiply(vec: Vector2): Vector2;
  multiply(x: number | Vector2, y?: number): Vector2 {
    if (typeof x === 'number') {
      const yy = y != null ? y : x;
      return new Vector2(this.x * x, this.y * yy);
    } else {
      return new Vector2(this.x * x.x, this.y * x.y);
    }
  }

  add(x: number, y: number): Vector2;
  add(vec: Vector2): Vector2;
  add(x: number | Vector2, y: number = 0): Vector2 {
    if (typeof x === 'number') {
      return new Vector2(this.x + x, this.y + y);
    } else {
      return new Vector2(this.x + x.x, this.y + x.y);
    }
  }

  lengthSqr(): number {
    return this.x * this.x + this.y * this.y;
  }

  normalize(): Vector2 {
    const sqr = this.lengthSqr();
    return this.multiply(1 / Math.sqrt(sqr));
  }

  toString(): string {
    return `{ x: ${this.x}, y: ${this.y} }`;
  }

  static lengthSqr(a: Vector2, b: Vector2): number {
    const dx = a.x - b.x;
    const dy = a.y - b.y;
    return dx * dx + dy * dy;
  }
  static dot(a: Vector2, b: Vector2): number {
    return a.x * b.x + a.y * b.y;
  }

  static lerp(a: Vector2, b: Vector2, l: number): Vector2 {
    return new Vector2(lerp(a.x, b.x, l), lerp(a.y, b.y, l));
  }

  static get zero() {
    return new Vector2();
  }
  static get one() {
    return new Vector2(1, 1);
  }
}

/**
 * Readonly Class for representing a 3d vector and utility functions.
 */
export class Vector3 implements Vector<Vector3> {
  constructor(
    readonly x: number = 0,
    readonly y: number = 0,
    readonly z: number = 0,
  ) {}

  multiply(x: number): Vector3;
  multiply(x: number, y: number, z: number): Vector3;
  multiply(vec: Vector3): Vector3;
  multiply(x: number | Vector3, y?: number, z?: number): Vector3 {
    if (typeof x === 'number') {
      const y2 = y != null ? y : x;
      const z2 = z != null ? z : x;
      return new Vector3(this.x * x, this.y * y2, this.z * z2);
    } else {
      return new Vector3(this.x * x.x, this.y * x.y, this.z * x.z);
    }
  }

  add(x: number, y: number, z: number): Vector3;
  add(vec: Vector3): Vector3;
  add(x: number | Vector3, y: number = 0, z: number = 0): Vector3 {
    if (typeof x === 'number') {
      return new Vector3(this.x + x, this.y + y, this.z + z);
    } else {
      return new Vector3(this.x + x.x, this.y + x.y, this.z + x.z);
    }
  }

  lengthSqr(): number {
    return this.x * this.x + this.y * this.y + this.x * this.x;
  }

  normalize(): Vector3 {
    const sqr = this.lengthSqr();
    return this.multiply(1 / Math.sqrt(sqr));
  }

  toString(): string {
    return `{ x: ${this.x}, y: ${this.y}, z: ${this.z} }`;
  }

  static lengthSqr(a: Vector3, b: Vector3): number {
    const dx = a.x - b.x;
    const dy = a.y - b.y;
    const dz = a.z - b.z;
    return dx * dx + dy * dy + dz * dz;
  }
  static dot(a: Vector3, b: Vector3): number {
    return a.x * b.x + a.y * b.y + a.z * b.z;
  }

  static lerp(a: Vector3, b: Vector3, l: number): Vector3 {
    return new Vector3(lerp(a.x, b.x, l), lerp(a.y, b.y, l), lerp(a.z, b.z, l));
  }

  static get zero() {
    return new Vector3();
  }
  static get one() {
    return new Vector3(1, 1, 1);
  }
}




