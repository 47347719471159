import React from "react"
import { Transition } from './AnimatedSvgs';
export function AboutMe({ isMobile, onClose }: { isMobile: boolean, onClose: () => void }) {
  const [visible, setVisible] = React.useState(false)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true)
    }, 100)

    return () => {
      clearTimeout(timer);
    }
  })

  return (
    <div>

      <Transition animationDidFinish={() => { }}/>
      <div className={isMobile ? "AboutLinkMobile" : "AboutLinkDesktop"} onClick={onClose}>Close</div>

      <div className={isMobile ? `AboutMeContents ${visible ? 'visible' : 'hidden'} AboutMeContentsMobile` : `AboutMeContents ${visible ? 'visible' : 'hidden'}  AboutMeContentsDesktop`}>

        <div className={isMobile ? `AboutMeContentsVideoContainer AboutMeContentsMobileVideoContainer` : `AboutMeContentsVideoContainer AboutMeContentsDesktopVideoContainer`}>
          {!isMobile &&
            <video className="AboutMeVideo" src='/images/aboutme.mp4' autoPlay loop></video>

          }
          {!isMobile &&
            <div className='aboutGradientRect' />
          }
          {isMobile &&
            <div className="AboutImg">
              <img style={{ width: "100%", borderRadius: 4 }} src="/images/aboutme.JPG" />
            </div>
          }
        </div>
        <div className={isMobile ? `AboutMeContentsText AboutMeContentsTextMobile` : `AboutMeContentsText AboutMeContentsTextDesktop`}>
          <div className='AboutMeContentsTextInner'>
            Hi I’m Jane, a software engineer and stop motion animator.
            I like bringing things to life, most of my career this has been through code, most recently through stop motion animation.
          </div>

          <div className='AboutMeContentsTextInner'>
            I have just completed an 8 month course with the <span className="AboutMeLink" onClick={() => { window.open("https://academy.aardman.com/", "_blank") }}> Aardman Academy </span> onsite in Bristol, directing and animating my own Animated Short - Guts.
          </div>
          <div className='AboutMeContentsTextInner'>
            I have previously worked as a Software Engineer (frontend and iOS) and a Creative Technologist for some of Australia’s biggest tech companies, including <span onClick={() => { window.open("https://canva.com", "_blank") }} className="AboutMeLink">Canva</span> and <span className="AboutMeLink" onClick={() => { window.open("https://atlassian.com", "_blank") }}>Atlassian</span>.
          </div>

          <div className='AboutMeContentsTextInner' style={{ paddingTop: 20 }}>
            <div onClick={() => { window.open("https://www.youtube.com/watch?v=G9207EJySaA", "_blank") }} className={`SWSectionAward SWSectionAwardDesktop`}>
              <span><img src="images/link.svg" /></span><span> YouTube Tutorial on a Banner I Implemented</span>
            </div>
            <div onClick={() => { window.open("http://pericles.ipaustralia.gov.au/ols/auspat/applicationDetails.do?applicationNo=2022204345", "_blank") }} className={`SWSectionAward SWSectionAwardDesktop`}>
              <span><img src="images/link.svg" /></span><span> Patent</span>
            </div>
            <div onClick={() => { window.open("https://www.linkedin.com/feed/update/urn:li:activity:7171813216270929922/", "_blank") }} className={`SWSectionAward SWSectionAwardDesktop`}>
              <span><img src="images/link.svg" /></span><span> International Womens Day 2024 - Aardman</span>
            </div>
            <div onClick={() => { window.open("https://www.vogue.com.au/vogue-codes/news/vogue-codes-summit-2021-speaker-learnings/image-gallery/c576dbb9c51f9638a196538dec8b6443", "_blank") }} className={`SWSectionAward SWSectionAwardDesktop`}>
              <span><img src="images/link.svg" /></span><span> Vogue Codes Summit 2021</span>
            </div>
            <div onClick={() => { window.open("https://www.apple.com/newsroom/2021/12/app-store-awards-honor-the-best-apps-and-games-of-2021/", "_blank") }} className={`SWSectionAward SWSectionAwardDesktop`}>
              <span><img src="images/link.svg" /></span><span> Canva App of the Year 2021</span>
            </div>
          </div>
        </div>


      </div>

    </div>
  )
}