import { ShaderManager } from './gl_base';

const GlowTrailShaderProgram = {
  vert: `
        attribute vec4 pos;
        uniform mat4 mat;
        varying highp vec2 screenCoordsUnit;

        void main() {
            gl_Position = mat * pos;
            screenCoordsUnit = pos.xy;
        }
    `,
  frag: `
        precision mediump float;
        uniform highp mat4 mat;
        uniform highp vec4 color;
        varying highp vec2 screenCoordsUnit;
        uniform highp vec2 mousePositions[16];
        uniform highp vec2 screenSize;
        uniform float radius;

       float line_segment(in vec2 p, in vec2 a, in vec2 b) {
          vec2 ba = b - a;
          vec2 pa = p - a;
          float h = clamp(dot(pa, ba) / dot(ba, ba), 0., 1.);
          return length(pa - h * ba);
        }

        void main() {
            highp vec2 screenCoords = vec2(screenCoordsUnit.x * screenSize.x, screenCoordsUnit.y * screenSize.y);

            float opacity = 0.0; // opacity of the point
            float distance = 0.0; //how close the point is to the cursor
            float distancePercentage = 1.0; //how close the point is to the cursor as % of radius

           int touch = 0;
           for (int i = 1; i < 16; i++) {
                highp vec2 mousePos = mousePositions[i].xy;
                highp vec2 mousePosB  = mousePositions[i - 1].xy;
                distance = line_segment(screenCoords, mousePos, mousePosB);
                if (distance < radius) {
                    touch = 1;

                    distancePercentage = distance/radius;
                    float i_float = float(i);
                    float circleOpacity = smoothstep(1.0,0.0,distancePercentage) * (1.0 - (i_float / radius));
                    opacity = max(opacity, circleOpacity);
                }
            }
            if (touch == 1) {
               gl_FragColor = vec4(color.xyz * opacity, opacity);
               return;
            }
            else {
                gl_FragColor = vec4(0, 0, 0, 0);
                return;
            }

        }
    `,
};

export function createGlowTrailShader(gl: WebGLRenderingContext) {
  const program = ShaderManager.createShader(
    gl,
    GlowTrailShaderProgram.vert,
    GlowTrailShaderProgram.frag,
  );
  const access = {
    pos: gl.getAttribLocation(program, 'pos'),
    mat: gl.getUniformLocation(program, 'mat'),
    color: gl.getUniformLocation(program, 'color'),
    mousePositions: gl.getUniformLocation(program, 'mousePositions'),
    screenSize: gl.getUniformLocation(program, 'screenSize'),
    radius: gl.getUniformLocation(program, 'radius'),
  };
  return {
    program,
    access,
  };
}
