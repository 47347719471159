/**
 * This module contains geometric functions for working with angles.
 */

export const π = Math.PI;
export const τ = 2 * π;

/** Converts an angle in degrees to an angle in radians. */
export function degreesToRadians(a: number) {
  return (a * π) / 180;
}

/** Converts an angle in radians to an angle in degrees. */
export function radiansToDegrees(a: number) {
  return (a * 180) / π;
}

/** Normalizes an angle, in degrees, into the range [-180, 180). */
export function normalizeDegrees(a: number) {
  if (-180 <= a && a < 180) {
    return a;
  }
  if (180 <= a && a < 360) {
    return a - 360;
  }

  // Normalize the angle from [0, 360)
  const a_ = ((a % 360) + 360) % 360;
  // Normalize from [-180, 180)
  return a_ >= 180 ? a_ - 360 : a_;
}

/** Normalizes an angle, in radians, into the range [-π, π). */
export function normalizeRadians(a: number) {
  return a - τ * Math.floor((a + π) / τ);
}
