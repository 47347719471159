import './App.css';
import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import { Vector2, Vector3 } from './geometry';
import { createGlowTrailShader } from './basic_shader';
import { updateBuffer, createBuffer, setBuffer } from './gl_base';
import { Geom } from './geom';
import gsap, { SteppedEase } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { useGSAP } from '@gsap/react';


export function SoftwareEngineerSection({ isMobile }: { isMobile: boolean }) {
    return (
        <div className='SoftwareEngineeringSection' id="SoftwareEngineeringContainer">
            <div className={isMobile ? `SoftwareEngineerSectionHeading SoftwareEngineerSectionHeadingMobile` : `SoftwareEngineerSectionHeading SoftwareEngineerSectionHeadingDesktop`}>
                Software Engineering Experience
            </div>

            <div className={isMobile ? `SWSection SWSectionMobile` : `SWTitle SWSectionDesktop`}>


                <div className={isMobile ? `SWSectionDate SWSectionDateMobile` : `SWSectionDate SWSectionDateDesktop`}>
                    Sept 2022 - Present
                </div>
                <div className={isMobile ? `SWSectionText SWSectionTextMobile` : `SWSectionText SWSectionTextDesktop`}>

                    <div className={isMobile ? `SWSectionTitle SWSectionTitleMobile` : `SWSectionTitle SWSectionTitleDesktop`}>
                        <div onClick={() => { window.open("https://canva.com", "_blank")}}>Creative Technologist - Canva</div><div></div>
                    </div>

                    <div className={isMobile ? `SWSectionDesc SWSectionDescMobile` : `SWSectionDesc SWSectionDescDesktop`}>
                        Rapidly prototyping new features for Canva's Video and AI offerings.
                        Implementing interactive animations for Campaigns such as the launch of Canva's Magic Studio - Canva's AI Suite.
                    </div>
                    <div className='SWSectionAwards'>
                
                        <div onClick={() => {window.open("https://www.youtube.com/watch?v=G9207EJySaA", "_blank")}}  className={isMobile ? `SWSectionAward SWSectionAwardMobile` : `SWSectionAward SWSectionAwardDesktop`}>
                           <span><img src="images/link.svg" /></span><span> YouTube Tutorial on a Banner I Implemented</span>
                        </div>
                    </div>
                    <div className={isMobile ? `SWSectionSkills SWSectionSkillsMobile` : `SWSectionSkills SWSectionSkillsDesktop`}>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            TypeScript
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            React
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            WebGL
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            AfterEffects
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            SVG Animation
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            SparkAR
                        </div>

                    </div>
                </div>
            </div>


            <div className={isMobile ? `SWSection SWSectionMobile` : `SWTitle SWSectionDesktop`}>


                <div className={isMobile ? `SWSectionDate SWSectionDateMobile` : `SWSectionDate SWSectionDateDesktop`}>
                    Jan 2020 - Sept 2022
                </div>
                <div className={isMobile ? `SWSectionText SWSectionTextMobile` : `SWSectionText SWSectionTextDesktop`}>

                    <div className={isMobile ? `SWSectionTitle SWSectionTitleMobile` : `SWSectionTitle SWSectionTitleDesktop`}>
                        <div onClick={() => { window.open("https://canva.com", "_blank")}}>Senior Frontend Developer - Canva</div><div></div>
                    </div>

                    <div className={isMobile ? `SWSectionDesc SWSectionDescMobile` : `SWSectionDesc SWSectionDescDesktop`}>
                        Implemented animation features on the Canva platform, enabling users to elevate their video and presentations through use of animations.

                        Key contributions included:
                        Recolourable Vector Stickers (Lottie): animated stickers users can add to their designs that can be recoloured (for example changing a red flower to be blue) and resized without the pixelation other formats would give, such as a gif.
                        Magic Animate: an AI driven tool that analyses the content of a design and applies animations to the elements (text, images, transitions, etc) in a complimentary style.
                        Page Transitions: the ability to add animated transitions between pages in a Canva design (presentation or video) with the option to customise the speed and direction of these transitions.
                    </div>
                    <div onClick={() => {window.open("http://pericles.ipaustralia.gov.au/ols/auspat/applicationDetails.do?applicationNo=2022204345", "_blank")}} className={isMobile ? `SWSectionAward SWSectionAwardMobile` : `SWSectionAward SWSectionAwardDesktop`}>
                    <span><img src="images/link.svg" /></span><span> Patent</span>

                        </div>
                    <div className={isMobile ? `SWSectionSkills SWSectionSkillsMobile` : `SWSectionSkills SWSectionSkillsDesktop`}>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            TypeScript
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            React
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            MobX
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            Protogen
                        </div>
                    </div>
                </div>
            </div>



            <div className={isMobile ? `SWSection SWSectionMobile` : `SWTitle SWSectionDesktop`}>
                <div className={isMobile ? `SWSectionDate SWSectionDateMobile` : `SWSectionDate SWSectionDateDesktop`}>
                    May 2017 - Jan 2020
                </div>
                <div className={isMobile ? `SWSectionText SWSectionTextMobile` : `SWSectionText SWSectionTextDesktop`}>

                    <div className={isMobile ? `SWSectionTitle SWSectionTitleMobile` : `SWSectionTitle SWSectionTitleDesktop`}>
                    <div onClick={() => { window.open("https://canva.com", "_blank")}}>iOS Engineer - Canva</div><div></div>
                    </div>

                    <div className={isMobile ? `SWSectionDesc SWSectionDescMobile` : `SWSectionDesc SWSectionDescDesktop`}>
                        Implementing features for the Canva iOS app.
                        Technical Lead for the Mobile Aquisition team, running and leading A/B testing for potential new features of the iOS app.
                    </div>

                    <div className={isMobile ? `SWSectionSkills SWSectionSkillsMobile` : `SWSectionSkills SWSectionSkillsDesktop`}>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            Swift
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            Objective-C
                        </div>

                    </div>
                </div>
            </div>


            <div className={isMobile ? `SWSection SWSectionMobile` : `SWTitle SWSectionDesktop`}>


                <div className={isMobile ? `SWSectionDate SWSectionDateMobile` : `SWSectionDate SWSectionDateDesktop`}>
                    Jun 2015 - July 2017
                </div>
                <div className={isMobile ? `SWSectionText SWSectionTextMobile` : `SWSectionText SWSectionTextDesktop`}>

                    <div className={isMobile ? `SWSectionTitle SWSectionTitleMobile` : `SWSectionTitle SWSectionTitleDesktop`}>
                    <div onClick={() => { window.open("https://atlassian.com", "_blank")}}>Senior iOS Developer - Atlassian</div><div></div>
                    </div>

                    <div className={isMobile ? `SWSectionDesc SWSectionDescMobile` : `SWSectionDesc SWSectionDescDesktop`}>
                        Lead iOS developer for the Confluence mobile app. This position required me to relocate from Sydney to Vietnam, where I assisted in taking the app from idea to market. This work included: collaborating with stakeholders to prototype proof-of-concepts; setting up the codebases from scratch and making technical decisions for the app's coding architecture.
                    </div>

                    <div className={isMobile ? `SWSectionSkills SWSectionSkillsMobile` : `SWSectionSkills SWSectionSkillsDesktop`}>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            Swift
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            Objective-C
                        </div>

                    </div>
                </div>
            </div>


            <div className={isMobile ? `SWSection SWSectionMobile` : `SWTitle SWSectionDesktop`}>


                <div className={isMobile ? `SWSectionDate SWSectionDateMobile` : `SWSectionDate SWSectionDateDesktop`}>
                    Aug 2012 - June 2015
                </div>
                <div className={isMobile ? `SWSectionText SWSectionTextMobile` : `SWSectionText SWSectionTextDesktop`}>

                    <div className={isMobile ? `SWSectionTitle SWSectionTitleMobile` : `SWSectionTitle SWSectionTitleDesktop`}>
                    <div onClick={() => { window.open("https://commbank.com.au", "_blank")}}>iOS Developer - Commonwealth Bank Australia</div><div></div>
                    </div>

                    <div className={isMobile ? `SWSectionDesc SWSectionDescMobile` : `SWSectionDesc SWSectionDescDesktop`}>
                        iOS development of the retail banking iPhone application “CommBank”.
                    </div>

                    <div className={isMobile ? `SWSectionSkills SWSectionSkillsMobile` : `SWSectionSkills SWSectionSkillsDesktop`}>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            Swift
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            Objective-C
                        </div>

                    </div>
                </div>
            </div>


            <div className={isMobile ? `SWSection SWSectionMobile` : `SWTitle SWSectionDesktop`}>


                <div className={isMobile ? `SWSectionDate SWSectionDateMobile` : `SWSectionDate SWSectionDateDesktop`}>
                    Jan 2011 - Aug 2012
                </div>
                <div className={isMobile ? `SWSectionText SWSectionTextMobile` : `SWSectionText SWSectionTextDesktop`}>

                    <div className={isMobile ? `SWSectionTitleNoLink SWSectionTitleMobile` : `SWSectionTitleNoLink SWSectionTitleDesktop`}>
                        <div>Mobile Developer - Creative Licence Digital</div><div></div>

                    </div>

                    <div className={isMobile ? `SWSectionDesc SWSectionDescMobile` : `SWSectionDesc SWSectionDescDesktop`}>
                        iOS & Backend Development of several iPhone apps for external clients including brands such as National Geographic Australia, Multiplex and Whiskas Cat food.
                    </div>

                    <div className={isMobile ? `SWSectionSkills SWSectionSkillsMobile` : `SWSectionSkills SWSectionSkillsDesktop`}>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            Objective-C
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            Python
                        </div>
                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            Google App Engine
                        </div>
                    </div>
                </div>
            </div>


            <div className={isMobile ? `SWSection SWSectionMobile` : `SWTitle SWSectionDesktop`}>


                <div className={isMobile ? `SWSectionDate SWSectionDateMobile` : `SWSectionDate SWSectionDateDesktop`}>
                    Oct 2010 - Jan 2011
                </div>
                <div className={isMobile ? `SWSectionText SWSectionTextMobile` : `SWSectionText SWSectionTextDesktop`}>

                    <div className={isMobile ? `SWSectionTitleNoLink SWSectionTitleMobile` : `SWSectionTitleNoLink SWSectionTitleDesktop`}>
                
                        <div>iOS Developer - Mashinery</div>
                    </div>

                    <div className={isMobile ? `SWSectionDesc SWSectionDescMobile` : `SWSectionDesc SWSectionDescDesktop`}>
                        iOS development of the iPhone application “Adoptapet”, for the RSPCA, Australia.
                    </div>

                    <div className={isMobile ? `SWSectionSkills SWSectionSkillsMobile` : `SWSectionSkills SWSectionSkillsDesktop`}>

                        <div className={isMobile ? `SWSectionSkill SWSectionSkillMobile` : `SWSectionSkill SWSectionSkillDesktop`}>
                            Objective-C
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
