import './AnimatedSvgs.css';
import { useRef } from 'react';
export const AnimatorSvg = ({ hovered }: { hovered: boolean }) => {

    const fillColor = hovered ? "#fd1174" : "#000"
    const strokeWidth = hovered ? 1 : 0;
    return (
        <svg id="ezvVEI6SjHu1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 79.819 30" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
            <path
                d="M5.145,0.116h1.869l5.124,13.859h-1.922L9.019,10.636h-5.879L1.922,13.975h-1.922L5.145,0.116ZM3.717,9.019h4.736L6.101,2.321h-.032L3.717,9.019Z"
                transform="translate(0 15)" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
            <path
                d="M18.396,5.104c.567,0,1.118.119,1.654.356.536.238.977.614,1.323,1.129s.52,1.186.52,2.011v5.376h-1.848v-5.02c0-.854-.199-1.475-.598-1.863s-.914-.582-1.543-.582c-.413,0-.811.117-1.192.352-.382.234-.695.557-.94.966s-.367.873-.367,1.391v4.757h-1.827v-8.622h1.827v1.533c.063-.238.234-.497.514-.777s.637-.518,1.071-.714.903-.294,1.407-.294l-.001.001Z"
                transform="translate(0 15)" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
            <path
                d="M25.42,2.405c-.329,0-.611-.117-.845-.353-.234-.234-.352-.516-.352-.845s.117-.612.352-.851.516-.357.845-.357c.217,0,.418.057.604.168.186.112.334.258.446.437.112.178.168.379.168.604c0,.329-.119.61-.357.845s-.525.353-.861.353v-.001Zm-.935,11.57v-8.62h1.827v8.62h-1.827Z"
                transform="translate(0 15)" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
            <path
                d="M39.448,5.104c.637,0,1.183.138,1.638.415.455.276.804.698,1.045,1.265s.362,1.292.362,2.173v5.02h-1.807v-4.651c0-.931-.129-1.631-.388-2.1-.26-.469-.732-.704-1.418-.704-.364,0-.712.1-1.045.3-.333.199-.605.506-.819.919-.213.412-.32.941-.32,1.585v4.651h-1.701v-4.651c0-.931-.161-1.631-.483-2.1s-.773-.704-1.354-.704c-.371,0-.719.097-1.044.289-.326.192-.59.495-.793.908s-.304.948-.304,1.606v4.651h-1.817v-8.621h1.817v1.323c.077-.217.243-.448.499-.693s.584-.453.987-.625c.403-.171.853-.257,1.35-.257.518,0,.952.104,1.302.314s.626.468.83.772c.203.304.343.597.42.876.098-.301.287-.604.567-.908s.634-.556,1.061-.756c.427-.199.899-.299,1.418-.299l-.003.002Z"
                transform="translate(0 15)" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
            <path
                d="M51.942,13.976v-1.68c-.056.175-.219.416-.488.725-.27.308-.637.586-1.103.834-.465.249-1.013.373-1.643.373-.798,0-1.521-.188-2.169-.567-.647-.378-1.162-.908-1.543-1.59-.382-.683-.572-1.482-.572-2.399s.19-1.717.572-2.399c.381-.683.896-1.214,1.543-1.596s1.371-.572,2.169-.572c.623,0,1.163.112,1.622.336.458.224.826.482,1.103.777.276.294.439.546.488.756v-1.617h1.806v8.62h-1.785v-.001ZM46.23,9.671c0,.623.137,1.162.41,1.616.272.456.628.808,1.065,1.056.438.249.904.373,1.401.373.532,0,1.008-.126,1.429-.378.42-.252.752-.607.997-1.066.245-.458.367-.992.367-1.601s-.122-1.143-.367-1.602c-.245-.458-.577-.815-.997-1.07-.421-.256-.896-.384-1.429-.384-.497,0-.964.126-1.401.378s-.793.605-1.065,1.061c-.273.455-.41.994-.41,1.617Z"
                transform="translate(0 15)" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
            <path
                d="M55.438,5.355h1.69v-3.539h1.816v3.539h2.216v1.564h-2.216v4.263c0,.504.09.875.269,1.113.178.237.418.356.719.356.267,0,.473-.049.62-.146.146-.099.234-.165.262-.2l.725,1.345c-.042.035-.154.102-.336.199s-.416.186-.703.263-.623.115-1.008.115c-.673,0-1.234-.205-1.686-.614s-.678-1.059-.678-1.947v-4.746h-1.69v-1.564-.001Z"
                transform="translate(0 15)" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
            <g id="ezvVEI6SjHu8_to" transform="translate(67.089,10.228)">
                <g id="ezvVEI6SjHu8_ts" transform="scale(1,1)" opacity="0">
                    <path
                        d="M67.115,14.228c-.89,0-1.679-.199-2.368-.599-.689-.398-1.23-.939-1.622-1.622-.393-.683-.588-1.454-.588-2.315s.195-1.638.588-2.331c.392-.692.933-1.242,1.622-1.648.689-.405,1.479-.608,2.368-.608.882,0,1.664.203,2.347.608.683.406,1.216.956,1.601,1.648.386.693.578,1.471.578,2.331s-.192,1.633-.578,2.315c-.385.683-.918,1.224-1.601,1.622-.683.399-1.465.599-2.347.599Zm0-1.564c.56,0,1.048-.13,1.465-.389.416-.259.74-.614.971-1.065s.347-.961.347-1.528-.115-1.078-.347-1.533c-.23-.454-.555-.814-.971-1.081-.417-.266-.905-.398-1.465-.398-.567,0-1.061.133-1.48.398-.42.267-.748.627-.982,1.081-.234.455-.352.967-.352,1.533s.117,1.077.352,1.528.562.807.982,1.065.913.389,1.48.389Z"
                        transform="translate(-67.089,-14.228)" fill={fillColor} stroke={fillColor} stroke-width={strokeWidth} />
                </g>
            </g>
            <path
                d="M75.452,13.976h-1.817v-8.621h1.817v1.491h-.053c.035-.183.164-.41.389-.683.224-.273.531-.519.924-.735.392-.217.86-.325,1.407-.325.398,0,.75.054,1.055.162.305.109.52.216.646.32l-.745,1.513c-.098-.099-.27-.198-.515-.3s-.55-.152-.913-.152c-.462,0-.857.144-1.187.431s-.579.632-.751,1.034c-.172.403-.257.775-.257,1.118v4.746.001Z"
                transform="translate(0 15)" fill={fillColor} stroke={fillColor} stroke-width={strokeWidth} />
        </svg>

    )
}


export const SoftwareEngineerSvg = ({ hovered }: { hovered: boolean }) => {
    const fillColor = hovered ? "#fd1174" : "#000"
    const strokeWidth = hovered ? 1 : 0;

    return (
        <svg onMouseEnter={() => { console.log("mouse in") }} id="eRAtuGscU6g1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 170.438 30" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">

            <g transform="translate(-1.853 11.824)">
                <path id="eRAtuGscU6g3" d="M169.492,3.316l.945.421-5.475,12.359-.945-.42l5.475-12.36Z" opacity="0"
                    fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g4"
                    d="M145.718,13.996v-1.005h2.024v-5.49h-2.024v-1.005h2.805l.24.975h.075c.34-.31.72-.575,1.14-.795s.92-.33,1.5-.33c.34,0,.612.065.817.195s.365.32.48.57c.114.25.192.552.232.907.04.354.06.757.06,1.207l-1.05.016c0-.635-.062-1.102-.188-1.398-.125-.298-.353-.447-.683-.447-.31,0-.59.045-.84.135s-.468.2-.652.329c-.186.13-.343.26-.473.389-.13.13-.225.24-.285.329v4.414h2.88v1.005h-6.06l.002-.001Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g5"
                    d="M143.662,13.051c-.18.16-.393.311-.637.45-.245.14-.513.26-.803.36s-.593.177-.907.232c-.315.055-.633.082-.953.082-.6,0-1.13-.093-1.59-.277s-.845-.45-1.155-.795-.545-.758-.705-1.237c-.159-.48-.239-1.021-.239-1.62c0-.63.087-1.19.262-1.681.176-.489.428-.899.758-1.229s.73-.582,1.2-.758c.47-.175.995-.263,1.575-.263.42,0,.835.056,1.245.165.409.11.772.318,1.087.623s.558.725.728,1.26.23,1.228.181,2.077h-5.79c0,.9.241,1.573.726,2.018.483.445,1.129.668,1.937.668.27,0,.536-.032.801-.098.265-.064.517-.143.756-.232s.451-.189.636-.3.326-.21.426-.3l.464.854-.003.001Zm-3.158-5.745c-.329,0-.64.035-.934.105-.295.069-.554.188-.778.353s-.408.38-.553.645c-.145.266-.237.593-.276.982h4.649c-.05-.66-.262-1.172-.636-1.537-.373-.365-.864-.548-1.473-.548h.001Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g6"
                    d="M134.663,13.051c-.18.16-.393.311-.637.45-.245.14-.513.26-.803.36s-.593.177-.907.232c-.315.055-.633.082-.953.082-.6,0-1.13-.093-1.59-.277s-.845-.45-1.155-.795-.545-.758-.705-1.237c-.159-.48-.239-1.021-.239-1.62c0-.63.087-1.19.262-1.681.176-.489.428-.899.758-1.229s.73-.582,1.2-.758c.47-.175.995-.263,1.575-.263.42,0,.835.056,1.245.165.409.11.772.318,1.087.623s.558.725.728,1.26.23,1.228.181,2.077h-5.79c0,.9.241,1.573.726,2.018.483.445,1.129.668,1.937.668.27,0,.536-.032.801-.098.265-.064.517-.143.756-.232s.451-.189.636-.3.326-.21.426-.3l.464.854-.003.001Zm-3.158-5.745c-.329,0-.64.035-.934.105-.295.069-.554.188-.778.353s-.408.38-.553.645c-.145.266-.237.593-.276.982h4.649c-.05-.66-.262-1.172-.636-1.537-.373-.365-.864-.548-1.473-.548h.001Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g7"
                    d="M119.123,9.121c0-.09-.002-.208-.007-.353-.005-.146-.013-.295-.022-.45-.011-.155-.022-.308-.038-.457-.015-.15-.032-.28-.052-.391h-1.006v-.975h2.011l.135,1.215h.075c.1-.17.232-.338.397-.503s.357-.314.577-.449c.22-.136.465-.243.735-.323s.555-.12.854-.12c.46,0,.868.051,1.223.15.355.101.65.277.885.532s.413.601.533,1.035c.12.436.18.988.18,1.657v4.306h-1.185v-4.071c0-.827-.135-1.451-.404-1.87s-.757-.629-1.464-.629c-.26,0-.511.053-.756.157-.244.104-.466.242-.665.412-.199.169-.373.366-.522.591s-.26.461-.329.711v4.699h-1.155v-4.875.001Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g8"
                    d="M110.304,13.996v-1.005h2.609v-5.49h-2.609v-1.005h3.795v6.495h2.55v1.005h-6.345Zm2.189-9.63c0-.271.091-.503.271-.698.18-.194.41-.292.689-.292.29,0,.533.098.728.292.195.195.293.428.293.698c0,.26-.098.479-.293.659-.194.181-.438.271-.728.271-.279,0-.51-.09-.689-.271-.18-.18-.271-.399-.271-.659Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g9"
                    d="M107.589,14.371c0,.469-.091.883-.271,1.236-.18.355-.428.648-.742.879-.315.229-.683.402-1.103.518-.42.113-.875.172-1.365.172-.59,0-1.104-.061-1.545-.18-.44-.121-.82-.275-1.14-.465l.568-1.066c.089.08.206.158.351.232.145.076.312.146.501.211s.392.117.606.158c.214.039.426.059.636.059.438,0,.805-.039,1.099-.119s.531-.205.71-.375c.18-.17.31-.391.39-.66.079-.27.119-.6.119-.99v-.84h-.06c-.229.33-.523.585-.883.766-.359.18-.822.27-1.392.27-1.137,0-1.972-.317-2.506-.952-.533-.635-.8-1.623-.8-2.963c0-1.28.34-2.247,1.02-2.902.681-.655,1.69-.982,3.03-.982.62,0,1.147.043,1.583.127.435.086.832.193,1.192.323v7.545l.002-.002Zm-3.346-1.2c.605,0,1.082-.157,1.431-.473.348-.314.591-.797.729-1.447v-3.555c-.437-.21-1.048-.315-1.832-.315-.795,0-1.421.235-1.878.705s-.685,1.19-.685,2.16c0,.43.039.822.119,1.178.079.354.206.662.38.922.174.261.402.463.685.607.283.146.634.218,1.051.218Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g10"
                    d="M92.123,9.121c0-.09-.002-.208-.007-.353-.005-.146-.013-.295-.022-.45-.011-.155-.022-.308-.038-.457-.015-.15-.032-.28-.052-.391h-1.006v-.975h2.011l.135,1.215h.075c.1-.17.232-.338.397-.503s.357-.314.577-.449c.22-.136.465-.243.735-.323s.555-.12.854-.12c.46,0,.868.051,1.223.15.355.101.65.277.885.532s.413.601.533,1.035c.12.436.18.988.18,1.657v4.306h-1.185v-4.071c0-.827-.135-1.451-.404-1.87s-.757-.629-1.464-.629c-.26,0-.511.053-.756.157-.244.104-.466.242-.665.412-.199.169-.373.366-.522.591s-.26.461-.329.711v4.699h-1.155v-4.875.001Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g11"
                    d="M83.334,3.496h6.164v1.109h-4.92v3.436h4.545v1.109h-4.545v3.735h4.995v1.11h-6.239v-10.499Z" opacity="0"
                    fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g12"
                    d="M71.664,13.051c-.18.16-.393.311-.637.45-.245.14-.513.26-.803.36s-.593.177-.907.232c-.315.055-.633.082-.953.082-.6,0-1.13-.093-1.59-.277s-.845-.45-1.155-.795-.545-.758-.705-1.237c-.159-.48-.239-1.021-.239-1.62c0-.63.087-1.19.262-1.681.176-.489.428-.899.758-1.229s.73-.582,1.2-.758c.47-.175.995-.263,1.575-.263.42,0,.835.056,1.245.165.409.11.772.318,1.087.623s.558.725.728,1.26.23,1.228.181,2.077h-5.79c0,.9.241,1.573.726,2.018.483.445,1.129.668,1.937.668.27,0,.536-.032.801-.098.265-.064.517-.143.756-.232s.451-.189.636-.3.326-.21.426-.3l.464.854-.003.001ZM68.506,7.306c-.329,0-.64.035-.934.105-.295.069-.554.188-.778.353s-.408.38-.553.645c-.145.266-.237.593-.276.982h4.649c-.05-.66-.262-1.172-.636-1.537-.373-.365-.864-.548-1.473-.548h.001Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g13"
                    d="M55.719,13.996v-1.005h2.024v-5.49h-2.024v-1.005h2.805l.24.975h.075c.34-.31.72-.575,1.14-.795s.92-.33,1.5-.33c.34,0,.612.065.817.195s.365.32.48.57c.114.25.192.552.232.907.04.354.06.757.06,1.207l-1.05.016c0-.635-.062-1.102-.188-1.398-.125-.298-.353-.447-.683-.447-.31,0-.59.045-.84.135s-.468.2-.652.329c-.186.13-.343.26-.473.389-.13.13-.225.24-.285.329v4.414h2.88v1.005h-6.06l.002-.001Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g14"
                    d="M47.349,7.081c.43-.25.915-.43,1.455-.54s1.069-.165,1.59-.165c.51,0,.928.08,1.253.24.324.16.579.367.765.622.185.255.31.545.375.87.064.325.098.652.098.982c0,.38-.011.785-.03,1.215-.021.431-.035.86-.045,1.29c0,.5.03.976.09,1.425h1.005v.976h-1.98l-.135-1.125h-.075c-.06.09-.15.205-.271.345-.12.141-.278.277-.474.412-.195.136-.435.253-.715.353-.281.101-.612.15-.993.15-.742,0-1.329-.189-1.761-.57-.431-.38-.646-.899-.646-1.56c0-.51.112-.935.339-1.275.226-.34.549-.6.97-.779.422-.181.931-.285,1.527-.315s1.267.01,2.009.12c.05-.46.058-.843.022-1.147s-.115-.548-.24-.728c-.126-.18-.302-.308-.527-.383s-.504-.112-.835-.112c-.452,0-.883.062-1.295.188-.411.125-.777.253-1.099.383l-.376-.87-.001-.002Zm2.22,6.045c.28,0,.54-.045.78-.135.24-.091.45-.205.63-.346.18-.14.33-.292.45-.457s.21-.322.27-.473v-1.05c-.52-.09-1-.135-1.439-.135s-.82.047-1.141.143c-.319.095-.569.242-.75.442-.18.2-.27.46-.27.779c0,.33.112.618.337.863.226.245.603.367,1.133.367v.002Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g15"
                    d="M41.814,8.146l1.5,4.17h.105l1.064-5.819h1.095l-1.47,7.5h-1.335L41.334,9.856h-.046l-1.5,4.141h-1.335l-1.574-7.5h1.154l1.215,5.85h.091l1.455-4.2h1.02v-.001Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g16"
                    d="M28.194,6.496h1.785v-1.485l1.185-.33v1.815h4.006v1.005h-4.006v3.54c0,.729.178,1.27.531,1.62.354.35.859.524,1.518.524.448,0,.839-.085,1.173-.255s.636-.354.905-.555l.389.885c-.351.28-.768.505-1.253.675s-.982.255-1.492.255c-.4,0-.777-.058-1.133-.172-.354-.115-.667-.293-.938-.532-.27-.24-.485-.548-.645-.923s-.24-.822-.24-1.343v-3.72h-1.785v-1.005.001Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g17"
                    d="M20.049,13.996v-1.005h1.785v-5.49h-1.785v-1.005h1.785v-.42c0-.94.245-1.62.735-2.04s1.19-.63,2.1-.63c.33,0,.625.02.885.06s.54.12.84.24l-.269.975c-.259-.109-.508-.183-.747-.218-.239-.034-.458-.052-.657-.052-.657,0-1.105.162-1.344.487s-.358.857-.358,1.598h3.105v1.005h-3.105v5.49h3.105v1.005h-6.075Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path id="eRAtuGscU6g18"
                    d="M10.584,10.246c0-1.21.315-2.168.945-2.873s1.53-1.058,2.7-1.058c.63,0,1.172.104,1.627.308.455.205.833.482,1.132.833.3.35.522.765.668,1.245.145.479.217.995.217,1.545c0,.6-.08,1.143-.24,1.627-.16.485-.395.898-.705,1.238s-.693.603-1.147.787c-.456.185-.973.277-1.553.277-.62,0-1.16-.103-1.62-.308s-.84-.482-1.14-.832c-.3-.351-.523-.766-.668-1.245-.145-.48-.217-.995-.217-1.545l.001.001Zm1.245,0c0,.35.042.7.127,1.05.085.351.222.665.411.945.189.279.436.505.74.675s.675.255,1.114.255c.797,0,1.398-.247,1.802-.742.403-.495.605-1.223.605-2.183c0-.36-.042-.713-.127-1.058s-.225-.657-.419-.938-.443-.505-.748-.675-.675-.255-1.114-.255c-.798,0-1.396.245-1.794.734-.399.49-.598,1.221-.598,2.19l.001.002Z"
                    opacity="0" fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
                <path
                    d="M2.98,12.511c.3.16.638.293,1.013.397.375.105.752.157,1.132.157.33,0,.64-.032.93-.097s.542-.17.757-.315.385-.327.51-.548c.125-.22.188-.479.188-.779c0-.41-.137-.745-.412-1.005-.275-.261-.618-.49-1.028-.69s-.855-.395-1.335-.585c-.48-.189-.925-.415-1.335-.675s-.752-.578-1.027-.953-.413-.852-.413-1.432c0-.41.083-.78.248-1.11s.397-.61.697-.84.663-.407,1.088-.533c.425-.124.897-.188,1.417-.188.6,0,1.145.043,1.635.128s.88.197,1.17.338l.03-.016v.03h.015l-.015.045v2.4h-1.11v-1.605c-.25-.06-.525-.109-.825-.149s-.62-.061-.96-.061c-.33,0-.628.04-.893.12s-.49.188-.675.322c-.186.135-.328.293-.428.473s-.15.37-.15.57c0,.39.137.715.413.975.275.261.617.493,1.027.697.41.205.855.408,1.335.607.48.2.925.433,1.335.698s.752.582,1.027.952.413.83.413,1.38c0,.45-.083.855-.248,1.215s-.4.67-.705.931c-.305.26-.68.46-1.125.6s-.947.21-1.507.21c-.38,0-.74-.03-1.08-.09-.34-.061-.652-.135-.938-.225-.285-.091-.54-.186-.765-.285-.225-.101-.403-.186-.533-.255l.015-.046v-2.43h1.11v1.665l.002.002Z"
                    fill={fillColor} stroke-width={strokeWidth} stroke={fillColor} />
            </g>
            <g id="eRAtuGscU6g20_to" transform="translate(6.901,77.854)">
                <line id="eRAtuGscU6g20" x1="0.375" y1="0.375" x2="0.375" y2="16.083" transform="translate(-0.375,-8.229)"
                    fill={fillColor} stroke="#231f20" stroke-width="0.75" stroke-linecap="round" stroke-miterlimit="10" />
            </g>
        </svg>
    )

}


export const LinkedInSvg = ({ hovered, height }: { hovered: boolean, height: number }) => {
    const fillColor = hovered ? "#fd1174" : "#000"
    return (<svg xmlns="http://www.w3.org/2000/svg" width={height} zoomAndPan="magnify"
        viewBox={`0 0 ${37} ${37}`} height={height} preserveAspectRatio="xMidYMid meet" version="1.0">
        <defs>
            <clipPath id="6e70c23d5d">
                <path d="M 0 0 L 37.007812 0 L 37.007812 37.007812 L 0 37.007812 Z M 0 0 " clipRule="nonzero" />
            </clipPath>
        </defs>
        <g clipPath="url(#6e70c23d5d)">
            <path fill={fillColor}
                d="M 29.296875 0 L 7.710938 0 C 3.453125 0 0 3.453125 0 7.710938 L 0 29.296875 C 0 33.554688 3.453125 37.007812 7.710938 37.007812 L 29.296875 37.007812 C 33.554688 37.007812 37.007812 33.554688 37.007812 29.296875 L 37.007812 7.710938 C 37.007812 3.453125 33.554688 0 29.296875 0 Z M 12.335938 29.296875 L 7.710938 29.296875 L 7.710938 12.335938 L 12.335938 12.335938 Z M 10.023438 10.378906 C 8.53125 10.378906 7.324219 9.164062 7.324219 7.660156 C 7.324219 6.160156 8.53125 4.941406 10.023438 4.941406 C 11.511719 4.941406 12.722656 6.160156 12.722656 7.660156 C 12.722656 9.164062 11.511719 10.378906 10.023438 10.378906 Z M 30.839844 29.296875 L 26.214844 29.296875 L 26.214844 20.65625 C 26.214844 15.460938 20.046875 15.855469 20.046875 20.65625 L 20.046875 29.296875 L 15.417969 29.296875 L 15.417969 12.335938 L 20.046875 12.335938 L 20.046875 15.058594 C 22.199219 11.070312 30.839844 10.773438 30.839844 18.875 Z M 30.839844 29.296875 "
                fillOpacity="1" fillRule="nonzero" />
        </g>
    </svg>)
}

export const InstagramSvg = ({ hovered, height }: { hovered: boolean, height: number }) => {
    const fillColor = hovered ? "#fd1174" : "#000"
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={height} zoomAndPan="magnify" viewBox={`0 0 ${37} ${37}`} height={height} preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="fc4fce2585"><path d="M 0 0 L 37.007812 0 L 37.007812 37.007812 L 0 37.007812 Z M 0 0 " clipRule="nonzero" /></clipPath></defs><g clipPath="url(#fff)"><path fill={fillColor} d="M 31.503906 37.007812 L 5.5 37.007812 C 2.46875 37.007812 0 34.539062 0 31.503906 L 0 5.5 C 0 2.46875 2.46875 0 5.5 0 L 31.503906 0 C 34.539062 0 37.007812 2.46875 37.007812 5.5 L 37.007812 31.503906 C 37.007812 34.539062 34.539062 37.007812 31.503906 37.007812 Z M 5.5 2 C 3.570312 2 2 3.570312 2 5.5 L 2 31.503906 C 2 33.4375 3.570312 35.007812 5.5 35.007812 L 31.503906 35.007812 C 33.4375 35.007812 35.007812 33.4375 35.007812 31.503906 L 35.007812 5.5 C 35.007812 3.570312 33.4375 2 31.503906 2 Z M 5.5 2 " fillOpacity="1" fillRule="nonzero" /></g><path fill={fillColor} d="M 18.503906 29.003906 C 12.710938 29.003906 8 24.292969 8 18.503906 C 8 12.710938 12.710938 8 18.503906 8 C 24.292969 8 29.003906 12.710938 29.003906 18.503906 C 29.003906 24.292969 24.292969 29.003906 18.503906 29.003906 Z M 18.503906 10 C 13.816406 10 10 13.816406 10 18.503906 C 10 23.191406 13.816406 27.003906 18.503906 27.003906 C 23.191406 27.003906 27.003906 23.191406 27.003906 18.503906 C 27.003906 13.816406 23.191406 10 18.503906 10 Z M 18.503906 10 " fillOpacity="1" fillRule="nonzero" /><path fill={fillColor} d="M 29.503906 11.003906 C 27.574219 11.003906 26.003906 9.429688 26.003906 7.5 C 26.003906 5.570312 27.574219 4 29.503906 4 C 31.433594 4 33.007812 5.570312 33.007812 7.5 C 33.007812 9.429688 31.433594 11.003906 29.503906 11.003906 Z M 29.503906 6 C 28.679688 6 28.003906 6.675781 28.003906 7.5 C 28.003906 8.328125 28.679688 9 29.503906 9 C 30.332031 9 31.003906 8.328125 31.003906 7.5 C 31.003906 6.675781 30.332031 6 29.503906 6 Z M 29.503906 6 " fillOpacity="1" fillRule="nonzero" /></svg>
    )
}

export const Mail = ({ height }: { height: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="37" zoomAndPan="magnify"
            viewBox="0 0 45 44.999999" height="37" preserveAspectRatio="xMidYMid meet" version="1.0">
            <rect x="-4.5" width="54" fill="#ffffff" y="-4.5" height="53.999999" fill-opacity="1" />
            <rect x="-4.5" width="54" fill="#ffffff" y="-4.5" height="53.999999" fill-opacity="1" />
            <path fill="#000000"
                d="M 45.011719 11.308594 C 45.011719 5.070312 39.953125 0.0117188 33.714844 0.0117188 C 27.089844 0.0117188 17.921875 0.0117188 11.296875 0.0117188 C 5.058594 0.0117188 0 5.070312 0 11.308594 C 0 17.933594 0 27.101562 0 33.726562 C 0 39.96875 5.058594 45.023438 11.296875 45.023438 C 17.921875 45.023438 27.089844 45.023438 33.714844 45.023438 C 39.953125 45.023438 45.011719 39.96875 45.011719 33.726562 Z M 5.625 15.40625 L 5.625 33.726562 C 5.625 36.859375 8.164062 39.398438 11.296875 39.398438 C 11.296875 39.398438 33.714844 39.398438 33.714844 39.398438 C 36.847656 39.398438 39.386719 36.859375 39.386719 33.726562 L 39.386719 15.40625 L 24.265625 27.515625 C 23.234375 28.335938 21.773438 28.335938 20.746094 27.515625 Z M 38.753906 8.703125 L 22.503906 21.714844 L 6.257812 8.703125 C 7.203125 6.882812 9.105469 5.640625 11.296875 5.640625 C 11.296875 5.640625 33.714844 5.640625 33.714844 5.640625 C 35.90625 5.640625 37.808594 6.882812 38.753906 8.703125 Z M 38.753906 8.703125 "
                fill-opacity="1" fill-rule="evenodd" />
        </svg>
    )
}

export const Transition = ({ animationDidFinish }: { animationDidFinish: () => void }) => {

    const stopCount = useRef(0)
    const ContainerRef = useRef<HTMLDivElement>(null)
    window.addEventListener("animationend", (event) => {

        stopCount.current += 1;
        if (stopCount.current === 8) {
            if (ContainerRef.current) {
                ContainerRef.current.hidden = true;
                animationDidFinish();
            }

        }
    });

    return (
        <div ref={ContainerRef} className='TransitionContainer'>

            <div className='DarkPinkContainer'>
                <div className='DarkPinkInner' />
            </div>
            <div className='LightPinkContainer'>
                <div className='LightPinkInner' />
            </div>
            <div className='DarkGreenContainer'>
                <div className='DarkGreenInner' />
            </div>
            <div className='LightGreenContainer'>
                <div className='LightGreenInner' />
            </div>
        </div>

    )

}