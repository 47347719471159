import { π, τ } from './angles';
import { Point } from './point';

/**
 * Geometric functions on points.
 */
export class Geom {
  static approximatelyEquals(a: Point, b: Point, ε = 1e-10) {
    return Math.max(Math.abs(a.x - b.x), Math.abs(a.y - b.y)) < ε;
  }
  /**
   * Returns the angle AOB between OA and OB.
   *
   * The angle is clockwise in a LH coordinate system, anti-clockwise in a RH coordinate system,
   * and will be in the range [-π, π).
   */
  static angleBetween(o: Point, a: Point, b: Point): number {
    const xoa = Math.atan2(a.y - o.y, a.x - o.x);
    const xob = Math.atan2(b.y - o.y, b.x - o.x);
    const aob = xob - xoa;
    return aob >= π ? aob - τ : aob < -π ? aob + τ : aob;
  }

  static innerProduct(a: Point, b: Point): number {
    return a.x * b.x + a.y * b.y;
  }
}
