export class ShaderManager {
    /** @nocollapse */
    static createShader(
      gl: WebGLRenderingContext,
      vertexShaderSource: string,
      fragShaderSource: string,
    ): WebGLShader {
      const loadShader = (type: number, source: string) => {
        const shader = gl.createShader(type);
        if (!shader) {
          throw new Error('could not create shader');
        }
  
        gl.shaderSource(shader, source);
        gl.compileShader(shader);
        if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
          const err = gl.getShaderInfoLog(shader);
          throw new Error('Error loading shader: ' + err ?? 'no information provided');
        }
        return shader;
      };
      const vertShader = loadShader(gl.VERTEX_SHADER, vertexShaderSource);
      const fragShader = loadShader(gl.FRAGMENT_SHADER, fragShaderSource);
      const shaderProgram = gl.createProgram()!;
      gl.attachShader(shaderProgram, vertShader);
      gl.attachShader(shaderProgram, fragShader);
      gl.linkProgram(shaderProgram);
  
      if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        throw new Error('failed to link');
      }
      return shaderProgram;
    }
  }
  
  export function createBuffer(gl: WebGLRenderingContext, positions: number[]): WebGLBuffer {
    const posBuffer = gl.createBuffer()!;
    gl.bindBuffer(gl.ARRAY_BUFFER, posBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.DYNAMIC_DRAW);
    return posBuffer;
  }
  
  export function updateBuffer(gl: WebGLRenderingContext, buffer: WebGLBuffer, positions: number[]) {
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.DYNAMIC_DRAW);
  }
  
  export function setBuffer(
    gl: WebGLRenderingContext,
    attribPointer: number,
    buffer: WebGLBuffer,
    num: number,
    type: number = gl.FLOAT,
  ) {
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
    gl.vertexAttribPointer(attribPointer, num, type, false, 0, 0);
    gl.enableVertexAttribArray(attribPointer);
  }
  