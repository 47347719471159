import { Vector2 } from './geometry';

/**
 * Linear interpolation between 2 numbers, clamped between the values
 */
export function lerp(a: number, b: number, lerp: number): number {
  return lerp < 0 ? a : lerp > 1 ? b : unclampedLerp(a, b, lerp);
}

/**
 * Linear interpolation between 2 numbers
 */
export function unclampedLerp(a: number, b: number, lerp: number): number {
  return a + (b - a) * lerp;
}

/**
 * Returns the lerp value used in a linear interpolation
 */
export function inverseLerp(a: number, b: number, value: number): number {
  return (value - a) / (b - a);
}

/**
 * Modulo operation that always returns a positive value.
 */
export function mod(v: number, size: number): number {
  return v >= 0
    ? v % size // does not use Math.abs as that would create a reflection when going from positive to negavite
    : // values
      (v % size) + size;
}

/**
 * Returns a vector of length 1 rotated by the radians. A value of radians:0 will product a (0,1)
 * vector and it will rotate clockwise
 */
export function radianToVector(radians: number): Vector2 {
  return new Vector2(Math.sin(radians), Math.cos(radians));
}

export type Matrix2x2 = [number, number, number, number];
export type Matrix3x3 = [number, number, number, number, number, number, number, number, number];

/**
 * Multiply 2 2D matrixes together. Used when multiplying 2 rotations together.
 */
export function multiplyMatrix2x2(a: Matrix2x2, b: Matrix2x2): Matrix2x2 {
  return [
    a[0] * b[0] + a[1] * b[2],
    a[0] * b[1] + a[1] * b[3],
    a[2] * b[0] + a[3] * b[2],
    a[2] * b[1] + a[3] * b[3],
  ];
}

/**
 * Multiply 2 3D matrixes together. Used when multiplying 2 rotations together.
 */
export function multiplyMatrix3x3(a: Matrix3x3, b: Matrix3x3): Matrix3x3 {
  return [
    a[0] * b[0] + a[3] * b[1] + a[6] * b[2],
    a[1] * b[0] + a[4] * b[1] + a[7] * b[2],
    a[2] * b[0] + a[5] * b[1] + a[8] * b[2],
    a[0] * b[3] + a[3] * b[4] + a[6] * b[5],
    a[1] * b[3] + a[4] * b[4] + a[7] * b[5],
    a[2] * b[3] + a[5] * b[4] + a[8] * b[5],
    a[0] * b[6] + a[3] * b[7] + a[6] * b[8],
    a[1] * b[6] + a[4] * b[7] + a[7] * b[8],
    a[2] * b[6] + a[5] * b[7] + a[8] * b[8],
  ];
}

/**
 * Multiply 2 3D matrixes together. Used when multiplying 2 rotations together.
 */
export function matrix3x3ToMatrix2x2(a: Matrix3x3): Matrix2x2 {
  return [a[0], a[1], a[3], a[4]];
}
